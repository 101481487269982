<template>
  <div class="justify-center full-height" style="max-width: 1980px;">
    <div class="q-pa-xl full-width row">
      <div class="row full-width q-mb-xl justify-between">
        <div class="text-h4">Module Preview</div>
      </div>

      <div class="row full-width justify-center q-my-lg">
        <q-input v-model="searchText" filled label="Search For a Module" class="col-8">
          <template v-slot:prepend>
            <q-icon name="search" class="primaryText-text" />
          </template>
          <template v-slot:append v-if="searchText && searchText.length > 0">
            <q-icon name="close" @click="searchText = null" class="cursor-pointer" />
          </template>
        </q-input>
      </div>

      <div class="row justify-center q-pl-md q-mt-xl">
        <div class="col-md-12">
          <div v-if="modules.length > 0" class="row q-gutter-lg" style="min-height: 234px">
              <q-card flat bordered style="min-width: 250px;" v-for="(module) in modules" :key="module.uid" class="bg-lmsBackground primaryText-text">
                <q-img :src="module.thumbnail_path">
                  <div class="absolute-bottom">
                    {{ module.title }}
                  </div>
                </q-img>
                <div class="q-mt-md">
                  <div class="q-px-md">
                    <div class="row items-center q-my-sm">
                      <q-icon name="calendar_today" class="q-mr-md" size="sm" />
                      <div class="text-subtitle2 text-italic secondaryText-text">{{ getDateFormat(module.updated_at) }}</div>
                    </div>
                    <div class="row items-center q-my-sm">
                      <q-icon name="business" class="q-mr-md" size="sm" />
                      <div class="text-subtitle2 text-italic secondaryText-text">{{ getMemberName(module.customer_uid) }}</div>
                    </div>
                  </div>
                  <div class="row justify-center q-my-sm">
                    <ModulePreview :moduleUid="module.uid" />
                  </div>
                </div>
              </q-card>
          </div>
          <div v-if="searchText && modules.length < 1" class="row full-width text-center text-h5 text-italic">Sorry, no modules match</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { date } from 'quasar'
import ModulePreview from 'components/ModulePreview'
export default {
  components: { ModulePreview },
  data () {
    return {
      modules: [],
      searchText: null,
      customers: []
    }
  },
  watch: {
    searchText (newVal) {
      if (newVal) {
        this.searchForModule()
      } else {
        this.modules = []
      }
    }
  },
  methods: {
    getMemberName (pUid) {
      let customer = this.customers.find(customer => customer.uid === pUid)
      return customer.name || 'Not Found'
    },
    getDateFormat (pDate) {
      return date.formatDate(pDate, 'MM/DD/YYYY')
    },
    searchForModule () {
      if (!this.searchText) {
        this.modules = []
        return
      }
      this.$moduleSearch.clearCache()
      this.$moduleSearch.search([
        {
          indexName: this.$consts.MODULES_INDEX,
          query: this.searchText
        }
      ], (err, content) => {
        if (err) throw err
        this.modules = content.results['0'].hits
      })
    }
  },
  async created () {
    this.$store.dispatch('auth/getGlobalLocations').then(res => {
      this.customers = res
    })
  }
}
</script>
